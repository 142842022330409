import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { map, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { deleteModalFn } from '~/shared/modal/delete/delete-modal';
import { createFormData } from '~/shared/utils/object/create-form-data';

import { DocumentModel } from './document.model';

@Injectable({ providedIn: 'root' })
export class DocumentsTemplatesApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'documents/templates',
  })
  private readonly endpoint!: CrmEndpoint<DocumentModel>;

  private deleteModal = deleteModalFn();

  get(id: string) {
    return this.endpoint.read(id);
  }

  update(id: string, body: CrmDictionary) {
    return this.endpoint.update(id, body);
  }

  list<Params>(params: Params) {
    return this.endpoint.list({ params });
  }

  delete(model: DocumentModel) {
    return this.deleteModal({
      message: 'documentTemplates.modal.delete.message',
      context: { value: model.name },
    }).pipe(switchMap(() => this.update(model._id, { status: 'inactive' })));
  }

  listAll<Params>(params: Params) {
    return this.endpoint.listAll({ params });
  }

  upload(file: File, params?: CrmDictionary) {
    const body = createFormData(params);
    body.append('file', file as unknown as Blob);

    return this.endpoint.create(body);
  }

  overwrite(
    id: string,
    file: File,
    params?: CrmDictionary,
  ): Observable<DocumentModel> {
    const body = createFormData(params);
    body.append('file', file as unknown as Blob);

    return this.endpoint.update(id, body);
  }

  downloadDocument<Body>(template: string, body: Body) {
    return this.endpoint.request<Blob>('POST', [template, 'render'].join('/'), {
      responseType: 'blob',
      params: { mode: 'download' },
      body,
    });
  }

  renderDocument<Body>(id: string, body: Body) {
    return this.endpoint.request<DocumentModel>(
      'POST',
      [id, 'render'].join('/'),
      { body },
    );
  }

  renderGdprDocument(
    user: string,
  ): Observable<{ gdpr: DocumentModel; document: DocumentModel }> {
    return this.getGdprTemplate().pipe(
      switchMap((gdpr) =>
        this.renderDocument(gdpr._id, {
          payload: { inputs: {} },
          load: { user },
          tags: [user, 'gdpr'],
        }).pipe(map((document) => ({ gdpr, document }))),
      ),
    );
  }

  getContent(id: string) {
    return this.endpoint
      .request<string>('GET', [id, 'resource'].join('/'), {
        responseType: 'text',
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (isDevMode() && err.status === 0) {
            return of('dev CORS error - fallback content');
          }

          return throwError(() => err);
        }),
      );
  }

  private getGdprTemplate() {
    return this.listAll({ type: 'template', tags: 'gdpr' }).pipe(
      map((documents) => documents[0]),
    );
  }
}
