import { Component, OnInit } from '@angular/core';
import { CrmSafeAny } from 'common-module/core/types';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  standalone: true,
  selector: 'app-signature-required',
  template: `<i nz-icon [nzType]="type" [class]="class"></i>`,
  styles: [
    `
      :host {
        font-size: 20px;
        line-height: 1;
        text-align: center;
      }
    `,
  ],
  imports: [NzIconModule],
})
export class SignatureRequiredComponent implements OnInit {
  public row!: CrmSafeAny;
  public data!: 'true' | 'false';

  public type!: string;
  public class!: string;

  public ngOnInit() {
    if (this.data === 'true') {
      this.type = 'check-circle';
      this.class = 'green-text';
    } else {
      this.type = 'close-circle';
      this.class = 'red-text';
    }
  }
}
